.pensionAsterisk .asteriskField {
  display: inline;
}

.btn {
  border-radius: 40px;
}

.green-text {
  color: $green !important;
}

.orange-text {
  color: $orange !important;
}

.datepicker table tr td span.active {
  background-color: #52ae32 !important;
  background-image: linear-gradient(to bottom, #52ae32, #52ae32) !important;
}
